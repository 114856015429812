
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: 'contract',
      title: '契約',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "商家清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "商家清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true },
        { field: "Merchant.Name", title: "商家", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.ContractType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "StartTime", title: "開始時間", showHeaderOverflow: true, showOverflow: true },
        { field: "EndTime", title: "開始時間", showHeaderOverflow: true, showOverflow: true },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.ContractStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => model.dispatch("contract/query", params) : undefined,
        queryAll: model ? () => model.dispatch("contract/query") : undefined,
        save: model ? (params) => model.dispatch("contract/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: 'Status', title: '狀態', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.ContractStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'MerchantId', title: '商家', span: 12, slots: { default: 'column-merchant-id' } },
        { field: 'Type', title: '類型', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.ContractType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'StartTime', title: '開始時間', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '請輸入日期' } } },
        { field: 'EndTime', title: '結束時間', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '請輸入日期' } } },
        { field: 'Memo', title: '備忘錄', span: 24, itemRender: { name: '$textarea', attrs: { rows: '20' }, props: { resize: 'none', autosize: { minRows: 10 }, placeholder: '請輸入文字' } } },
      ],
      rules: {
        MerchantId: [{ required: true }],
      },
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇商家",
      textField: "Name",
      valueField: "Id",
      dropdownWidth: 450,
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Principal.Name", title: "負責人", showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("merchant/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("merchant/query", params), // eslint-disable-line
      },
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_STORAGE_SERVICE}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
  },
});
